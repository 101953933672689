import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In honor of Valentine’s day, the team at Dashbot took a closer look at men’s versus women’s chatbot usage on Facebook Messenger.`}</p>
    <p>{`We process more than 3 billion messages a month and used January’s data for analysis.`}</p>
    <h2>{`Women’s usage on the rise`}</h2>
    <p>{`In general, there are more men using Facebook chatbots than women. On average, about 59% of a chatbot’s users are men, whereas 37% are women, and 4% are unknown.`}</p>
    <p>{`Men tend to use chatbots longer as well, with session times about 16% longer than women, although the amount of messages tend to be about the same – men are apparently slower typers! Men have higher engagement as well, with 27% more sessions per user than women.`}</p>
    <p>{`About 15% of both men and women use more than one bot a month.`}</p>
    <p>{`Women’s usage has risen over the past year. In our `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/blog/men-chat-from-mars-women-chat-from-venus"
      }}>{`report last year`}</a>{`, only 28% of a bot’s users were women on average. Men’s engagement as measured by sessions be user was 50% higher than women’s at the time, as opposed to 27% now. Similarly, last year only 10.6% of women used multiple bots which has increased.`}</p>
    <h2>{`What are they saying in these messages?`}</h2>
    <p>{`The top messages in for men and women are about the same. Although, it’s interesting to see “ok” higher up than “hi” and “hello” for women, as those and the thumbs up tend to be the top three overall regardless of gender when `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/blog/examining-chatbot-messaging-over-the-year"
      }}>{`we looked at this in the past`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/02/06120628/gender-messages-jan.png",
        "alt": null
      }}></img></p>
    <h2>{`The “Romance” languages`}</h2>
    <p>{`While Romance, may have a different meaning, we looked at a few of the Romance languages to see how men and women chat. For the most part, the top ten messages were fairly similar between genders. However, the top messages for Portuguese women were pretty interesting – there tends to be more welcoming messages of good morning, afternoon, and night (bom dia, boa tarde, and boa noite respectively). Another interesting message was “cc” in French, which is slang for “coucou” or “hey”/”hello” in English.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/02/06120628/gender-msg-lang-1024x547-1024x547.png",
        "alt": null
      }}></img></p>
    <h2>{`Love Stickers Together`}</h2>
    <p>{`As we have mentioned in previous reports, users communicate in more than just words – they use images, stickers, audio, video and more.`}</p>
    <p>{`We looked at men vs women’s use of stickers. Fairly equal percentages of men and women use stickers in chats – it’s only about a 1% difference more for men.`}</p>
    <p>{`The top 10 most common stickers, based on the number of bots, are about the same for both. However, the next ten is where differences start to become more apparent.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/02/06120626/gender-stickers-jan-357x1024.png",
        "alt": null
      }}></img></p>
    <p>{`In the next ten, we see more variety in the stickers and the relative frequency of use.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/02/06120625/gender-stickers-jan-20-357x1024.png",
        "alt": null
      }}></img></p>
    <h2>{`What about love?`}</h2>
    <p>{`As it is Valentine’s day, we wanted to see where all the love is.`}</p>
    <p>{`While both men’s and women’s average sentiment across bots are positive, hovering slightly over neutral, women’s sentiment is almost double that of men.`}</p>
    <p>{`Who says “love” more though? Or “luv”, “I luv u”, “I love you”…`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/02/06120624/love-messages2.png",
        "alt": null
      }}></img></p>
    <p>{`While a higher percentage of messages containing “love” come from women, men tend to send the messages at a higher frequency. About 51% of messages containing love come from women, as compared to 44% from men and 5% unknown. However, men tend to send double the number of messages per user containing a variation of the word love than women. Fewer men may say it, but when they do, it’s more often.`}</p>
    <p>{`We’re big fans of chatbots and love the data and insights that can be gleaned. It’s all unstructured data – users can say, or write, whatever they like – telling bots exactly what they want, and what they think of the results after. It leads to some pretty interesting actions you can take on the data.`}</p>
    <p>{`It’s important to look at your data and analytics to better understand your users’ behaviors to build a better bot experience, and share the love back to your users.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Facebook Messenger, Alexa, Google Home, Slack, Twitter, Kik and any other conversational interface.`}</em></p>
    <p><em parentName="p">{`We’re funded by ffVC, Bessemer, Samsung Next, BDMI, Scrum Ventures, Rembrandt, Recruit, and R/GA.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      